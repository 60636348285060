(function ()
{
    function readCookie(name)
    {
        const nameEq = name + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++)
        {
            let c = ca[i];
            while (c.charAt(0) === " ")
            {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEq) === 0)
            {
                return c.substring(nameEq.length, c.length);
            }
        }
        return null;
    }

    function createCookie(name, value, days)
    {
        let expires;
        if (days)
        {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
        }
        else
        {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function getPreferredStyleSheet()
    {
        let i;
        let a;
        for (i = 0; (a = document.getElementsByTagName("link")[i]); i++)
        {
            const relAttr = a.getAttribute("rel");
            if (relAttr != null &&
                relAttr.indexOf("style") !== -1 &&
                relAttr.indexOf("alt") === -1 &&
                a.getAttribute("title"))
            {
                return a.getAttribute("title");
            }
        }
        return null;
    }

    function getActiveStyleSheet()
    {
        let i;
        let a;
        for (i = 0; (a = document.getElementsByTagName("link")[i]); i++)
        {
            const relAttr = a.getAttribute("rel");
            if (relAttr != null && relAttr === "alternate stylesheet" && a.getAttribute("href") && a.getAttribute("title") && !a.disabled)
            {
                return a.getAttribute("title");
            }
        }
        return null;
    }

    function onLoad()
    {
        const cookie = readCookie("style");
        const title = cookie
            ? cookie
            : getPreferredStyleSheet();
        SetActiveStyleSheet(title);
    }

    function onUnload()
    {
        const sheet = getActiveStyleSheet();
        const sheetName = sheet != null ? sheet : "";
        createCookie("style", sheetName, 365);
    }

    function SetActiveStyleSheet(title)
    {
        let i;
        let a;
        for (i = 0; (a = document.getElementsByTagName("link")[i]); i++)
        {
            const relAttr = a.getAttribute("rel");
            if (relAttr != null && relAttr === "alternate stylesheet" && a.getAttribute("title"))
            {
                a.disabled = true;
                if (a.getAttribute("title") === title)
                {
                    a.disabled = false;
                }
            }
        }
    }
    window.addEventListener("load", onLoad);
    window.addEventListener("beforeunload", onUnload);
    window.SetActiveStyleSheet = SetActiveStyleSheet;
})();